/*** IMPORTS FROM imports-loader ***/
(function() {

define( [
	"./core",
	"./attributes/attr",
	"./attributes/prop",
	"./attributes/classes",
	"./attributes/val"
], function( jQuery ) {

// Return jQuery for attributes-only inclusion
return jQuery;
} );

}.call(window));