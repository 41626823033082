/*** IMPORTS FROM imports-loader ***/
(function() {

//window.CKEDITOR_BASEPATH='./vendor/ckeditor/';
var $  = require('jquery'),
    Loader = require('./loader.js'),
    ck = {};

ck.basic_editor = function(ls){
    var load_after_lib = function(){
        var i;
        for(i=0;i<ls.length;i++){
          if (window.CKEDITOR.instances[ls[i]]==undefined){
              window.CKEDITOR.replace( ls[i] ,
                {
                    customConfig : '../../config/ckeditor_config.js'
                });
              ck.rte_fields.push(ls[i]);
          }
        }
    };
    if(window.CKEDITOR){
        load_after_lib();
    }else{
        Loader.loadCKEditor(load_after_lib);
    }
}
ck.rte_fields = [];
ck.clean_ckeditor = function(){
    var k,f,editor,s;
    for(k=0;k<ck.rte_fields.length;k++){
        f = ck.rte_fields[k];
        editor = window.CKEDITOR.instances[f];
        if(editor){
            s = editor.getData();
            editor.destroy(true);
            $('#'+f).html(s);
        }
    }
    ck.rte_fields = [];
}
$(document).on('on_main_region_cleanup', ck.clean_ckeditor);

module.exports = ck;

}.call(window));