/*** IMPORTS FROM imports-loader ***/
(function() {

var $ = require('jquery');
var current_scope;

var set_scope=function(scope, target){
    if(target!='main-region' && target!='app-container' ){
        return;
    }
    var t = $('h1#title');
    if(t.length==0){
        return;
    }
    if(scope=='admin-web'){
        t.css('background-color', '#99F');
    }else if(scope=='admin-red'){
        t.css('background-color', 'rgb(232, 227, 82)');
    }else if(scope=='admin-grupo'){
        t.css('background-color', 'rgb(141, 27, 1)');
    }else if(scope=='miembro'){
        t.css('background-color', '#CEC');
    }else if(scope=='logueado'){
        t.css('background-color', '#EEE');
    }else{
        t.css('background-color', '');
    }
};

module.exports = set_scope;

}.call(window));