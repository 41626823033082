/*** IMPORTS FROM imports-loader ***/
(function() {

define( [
	"../var/pnum"
], function( pnum ) {

return new RegExp( "^(?:([+-])=|)(" + pnum + ")([a-z%]*)$", "i" );

} );

}.call(window));