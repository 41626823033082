/*** IMPORTS FROM imports-loader ***/
(function() {

var web2py = require('./web2py.js');
window.web2py = web2py;

var Loader = require('./loader');
window.Loader = Loader;
Loader.init();

var Ractive = require( 'ractive'),
    RouterComponent = require('../components/global/router'),
    SalirSinGuardar = require('../components/global/salir_sin_guardar'),
    Cargando = require('../components/global/cargando'),
    Ayuda = require('../components/global/ayuda'),
    Footer = require('../components/global/footer'),
    RouterPlugin = require('./plugins/router'),
    routes = require('./config/routes.js');
require('bootstrap');
//To disable debug mode when your app is minified, add this snippet:
Ractive.DEBUG = /unminified/.test(function(){/*unminified*/});

var Template = require('../views/app.html');

var App = new Ractive({
    el: '#app-container',
    template: Template,
    components: {
        Router: RouterComponent
    },
    oninit: function(){
        RouterPlugin.init(routes, this.onNavigation.bind(this));
        Loader.reload_menus();
    },
    onNavigation: function(error, navigationContext) {
        if (error) {
            console.warn('App::onNavigation# Error navigating:', error);
        }else {
            this.set({
                mainComponent: navigationContext.mainComponent
            });
            if(navigationContext.mainComponent=='Web2py'){
                Loader.load_link(navigationContext.path);
            }
            navigationContext.handled = (Loader.next_link.length==0);
        }
    },
    oncomplete: function(){
        RouterPlugin.navTo(document.URL.split("#!")[1] || "/");
    },
});

App.set_scope = require('./scope');
//no hay manera de cargar ckeditor con un require, asi que lo cargo on demand con getScript
//require('../vendor/ckeditor/ckeditor.js');
App.ck = require('./ckeditor_loader.js');

var salirsinGuardar = new SalirSinGuardar({
    el: '#container_salir_sin_guardar',
});
var cargando = new Cargando({
    el: '#container_cargando',
});
var ayuda = new Ayuda({
    el: '#container_ayuda',
});
var footer = new Footer({
    el: '#footer',
});

App.cargando = cargando;
App.ayuda = ayuda;
module.exports = window.App = App;

}.call(window));