/*** IMPORTS FROM imports-loader ***/
(function() {

define( [
	"../var/support"
], function( support ) {

support.focusin = "onfocusin" in window;

return support;

} );

}.call(window));