/*** IMPORTS FROM imports-loader ***/
(function() {

var Ractive = require('ractive'),
    Template = require('./web2py.html');

var Web2py = Ractive.components.Web2py = Ractive.extend({
	template: Template
});
Web2py._name = 'Web2py';

module.exports = Web2py;

}.call(window));