/*** IMPORTS FROM imports-loader ***/
(function() {

var Ractive = require('ractive'),
    Template = require('./cargando.html'),
    T = require('../../js/plugins/translations.js');

var Cargando = Ractive.components.Cargando = Ractive.extend({
	template: Template,
	data:{
	    T:T
	},
	show: function(){
        $('#modal_cargando').modal();
	},
	hide: function(){
        $('#modal_cargando').modal('hide');
	},
	oninit: function(){
        $(document).on('ajax:complete', function (e) {
           App.cargando.hide();
        });
        $(document).on('on_main_region_cleanup', function (e) {
           App.cargando.show();
        });
	}
});
Cargando._name = 'Cargando';

module.exports = Cargando;

}.call(window));