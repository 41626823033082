/*** IMPORTS FROM imports-loader ***/
(function() {

var Ractive = require('ractive');
var Web2pyComponent = require('./web2py');

/*
	This router has been built on top of the ideas from this Stack Overflow question:
	http://stackoverflow.com/questions/31075341/how-to-create-ractives-subcomponents-dynamically-and-change-them-programmatical
*/

var Router = Ractive.extend({
	template: '<router-handler/>',
	components: {
		'router-handler': function() {
		    var mainComponent = this.get('mainComponent');
			return mainComponent?mainComponent:"Web2py";
		}
	},
	oninit: function() {
		this.observe('mainComponent', function(newValue, oldValue) {
			this.reset();
		}, { init: false});
	}
});

module.exports = Router;

}.call(window));