/*** IMPORTS FROM imports-loader ***/
(function() {

var Ractive = require('ractive'),
    Template = require('./salir_sin_guardar.html'),
    T = require('../../js/plugins/translations.js');

var SalirSinGuardar = Ractive.components.SalirSinGuardar = Ractive.extend({
	data:{
	    T:T
	},
	template: Template
});
SalirSinGuardar._name = 'SalirSinGuardar';

module.exports = SalirSinGuardar;

}.call(window));