/*** IMPORTS FROM imports-loader ***/
(function() {

var Ractive = require('ractive'),
    Template = require('./footer.html'),
    T = require('../../js/plugins/translations.js');

var Footer = Ractive.components.Footer = Ractive.extend({
	template: Template,
	data:{
	    T:T
	}
});
Footer._name = 'Footer';

module.exports = Footer;

}.call(window));