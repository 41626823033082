/*** IMPORTS FROM imports-loader ***/
(function() {

var page = require('page'),
    Ractive = require('ractive');

navigationHandler = function(routeHandler, onNavigation) {  
    return function(context/*, next*/) {
        routeHandler(context, function(error) {
            onNavigation(error, context);
        })
    };
}

module.exports = {
    init: function(routes, onNavigation) {

        routes.forEach(function(path_handler){
            var path = path_handler[0],
                routeHandler = path_handler[1];
            page(path, navigationHandler(routeHandler, onNavigation));
        });

        page({
//            standard options
        });
    },
    navTo: function(url) {
        page.show(url);
    },
    stopHandler: function(context, next){
        window.location = context.path;
    }
};

}.call(window));