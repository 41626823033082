/*** IMPORTS FROM imports-loader ***/
(function() {

var Ractive = require('ractive'),
    Template = require('./ayuda.html'),
    T = require('../../js/plugins/translations.js'),
    RouterPlugin = require('../../js/plugins/router');

var Ayuda = Ractive.components.Ayuda = Ractive.extend({
	template: Template,
	help_link: function(link){
	    var k = link.search(/(\.html|\.load)/);
	    if(k>0)
	        {return 'ayuda_' + link.substring(1,k).replace('/','_')}
	    return ;
	},
	data:{
	    T:T,
	    editable:false
	},
	oncomplete: function(){
	    componente_ayuda = this;
        $('#modal_ayuda .modal-body').on('click', 'a', function (e) {
            var link = this.href,
                wiki_link = link.slice(link.lastIndexOf('/')+1);
            if(link.indexOf('attachment')==-1){
                componente_ayuda._muestra_pagina(wiki_link);
            }else{
                //Almacena la pagina en la que estabamos en la historia, descarga el archivo
                //y establece el titulo
                RouterPlugin.navTo(link);
                document.title = T('Descargando archivo: ') + wiki_link;
            }
            e.preventDefault();
        });
	},
	_muestra_pagina: function(url){
	    componente_ayuda = this;
        $('#modal_ayuda').modal();
        componente_ayuda.set({
            ayuda_title: T('Cargando ayuda para esta página'),
            ayuda_body: '...'
        });
        //'?callback=' es necesario!
        //http://usejquery.com/blog/jquery-cross-domain-ajax-guide
	    $.getJSON(Loader.LINK_WIKI+'/plugin_wiki/page.json/' + url + '?callback=', function(response){
            componente_ayuda.set({
                ayuda_title: response.titulo,
                ayuda_body: response.contenido,
                editable: Loader.LINK_WIKI.length==0
            });
	    })
	},
	show: function(){
        this._muestra_pagina(this.help_link(Loader.CURRENT_PAGE));
	},
	hide: function(){
        $('#modal_Ayuda').modal('hide');
	}
});
Ayuda._name = 'Ayuda';

module.exports = Ayuda;

}.call(window));