/*** IMPORTS FROM imports-loader ***/
(function() {

var RouterPlugin = require('../plugins/router.js');

var useComponent = function(componentName){
    return function(context, next){
        context.mainComponent = componentName;
        next()
    }
};

var regex_content = /.*(pdf|xls|ods|csv|jpg|png|gif|bmp|jpeg).*/;
var routes = [
    ['/myhome', useComponent('HomePage')],
    [regex_content, RouterPlugin.stopHandler],
    ['*', useComponent('Web2py')]
];

module.exports = routes;

}.call(window));