/*** IMPORTS FROM imports-loader ***/
(function() {

var $ = require('jquery'),
    RouterPlugin = require('./plugins/router'),
    web2py = require('./web2py');

var Loader = {

    DEFAULT_PAGE: '/default/index.load',
    CURRENT_PAGE: '/',
    CURRENT_PARAMS: [],
    STATIC_URL: '/static',
    LINK_WIKI: '',

    loaded_scripts: {},
    cambios_sin_guardar: false,
    next_link: '',

    init: function(){
        $(function(){
            //salir sin guardar
            $('#continuar').on('click', function(){
                $('#modal_salir_sin_guardar').modal('hide');
                Loader.no_hay_cambios();
                RouterPlugin.navTo(Loader.next_link);
            });
            $(document).on('change', 'input[type!="submit"],select,textarea', Loader.hay_cambios);
            $(document).on('change', 'input[type="submit"]', Loader.no_hay_cambios);
            $(document).on('submit', 'form', Loader.no_hay_cambios);
            $(window).on('beforeunload', Loader.confirmacion_salida);
        })
        $.get('/default/version.json', function(response){
            Loader.STATIC_URL = response.static_path;
            Loader.LINK_WIKI  = response.wiki_path;
        });
    },

    hay_cambios: function(){
        if(!this.classList || !this.classList.contains('vete_sin_problema')){
            Loader.cambios_sin_guardar = true;
        }
    },

    no_hay_cambios: function(){
        Loader.cambios_sin_guardar = false;
    },

    confirmacion_salida: function(e){
        if(Loader.cambios_sin_guardar){
            e.preventDefault();
            return 'Hay datos sin guardar.';
        }
    },

    reload_menus: function(){
        web2py.component('/karakolas/default/menu.load','response_menu');
    },

    direct_html: /^\/?(admin\/|appadmin\/|webadmin\/)/,

    load_link: function(link){
        var unloadEvent;
        //http://www.joezimjs.com/javascript/the-lazy-mans-url-parsing/
        var parser = document.createElement('a');
        if(link.search(/[a-zA-Z0-9]/)==-1){
            link = Loader.DEFAULT_PAGE;
        }
        parser.href = link;
        if(parser.pathname.search(/^\/?(static|.*\/download)|plugin_wiki\/attachment[^s]/)==-1 &
           parser.pathname.search(/(load|html)/)>=0 &
           parser.pathname.search(/\/front\//)==-1){
            if(parser.pathname.search(Loader.direct_html)==-1){
                parser.pathname = parser.pathname.replace(/(load|html)/, 'load');
            }
            link = parser.href;

            unloadEvent = $.Event('beforeunload');
            $(window).trigger(unloadEvent);
            if(!unloadEvent.isDefaultPrevented()){
                Loader.next_link = '';
                Loader.do_load_link(link);
            }else{
                Loader.next_link = link;
                $('#modal_salir_sin_guardar').modal();
            }
        }
    },

    do_load_link: function(link){
        var doc=$(document);
        //clean
        doc.trigger('on_main_region_cleanup');
        doc.off('scripts_loaded');
        Loader.no_hay_cambios();
        $('.flash').fadeOut();
        //http://www.joezimjs.com/javascript/the-lazy-mans-url-parsing/
        parser = document.createElement('a');
        parser.href = link;
        Loader.CURRENT_PAGE = parser.pathname;
        Loader.CURRENT_PARAMS = parser.search.substring(1).split('&');
        Loader.clear_style();
        web2py.component(link, 'main-region');
    },

    clear_style: function(){
        $('head').find('link.local').detach();
    },

    //Receives a sequence of scripts, and loads them sequentially
    getScriptSequence: function(seq, callback){
        var script,
             //http://www.joezimjs.com/javascript/the-lazy-mans-url-parsing/
            parser = document.createElement('a');
        if(seq.length==0){
            callback();
        }else{
            script = seq[0];
            parser.href = script;
            if(parser.pathname.slice(-3)=='.js'){
                if((Loader.loaded_scripts[parser.pathname] == undefined) ||
                   (parser.search.search('reload=1')>0) ){
                    //Levantamos el modal si hay que cargar alguna libreria que no esté
                    //ya cargada
                    App.cargando.show();
                    $.getScript(script, function(){
                        Loader.getScriptSequence(seq.slice(1), callback);
                    });
                    Loader.loaded_scripts[script] = Loader.CURRENT_PAGE;
                }else{
                    Loader.getScriptSequence(seq.slice(1), callback);
                }
            }else if(parser.pathname.slice(-4)=='.css'){
                $.get(script, function(data){
                $('<link rel="stylesheet" type="text/css" class="local" href="'+script+'" >')
                   .appendTo('head');
                Loader.getScriptSequence(seq.slice(1), callback);
                })
            }
        }
    },

    getScripts: function(scripts, callback) {
        var internalCallback, scripts_left;
        if (callback === undefined){
            callback = function(){
                $(document).trigger('scripts_loaded');
                App.cargando.hide();
            }
        }
        scripts_left = scripts.length;

        if(scripts_left>0){
            internalCallback = function () {
                scripts_left -= 1;
                if (scripts_left == 0) { callback(); }
            };
            scripts.forEach(function(seq) {
                Loader.getScriptSequence(seq, internalCallback);
            });
        }else{
            callback();
        }
    },
    loadCKEditor: function(callback){
        window.CKEDITOR_BASEPATH = Loader.STATIC_URL + '/front/src/vendor/ckeditor/';
        if(window.CKEDITOR===undefined){
            $.getScript(Loader.STATIC_URL + '/front/src/vendor/ckeditor/ckeditor.js', callback);
        }else{
            callback();
        }
    },
    loadCalendar: function(callback){
        if(window.Calendar===undefined){
            $.getScript(Loader.STATIC_URL + '/front/src/js/plugins/calendar_es.js', callback);
        }else{
            callback();
        }
    }
};

module.exports = Loader;

}.call(window));