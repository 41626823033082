/*** IMPORTS FROM imports-loader ***/
(function() {

define( [
	"../../core",
	"../../selector"
], function( jQuery ) {
	return jQuery.expr.match.needsContext;
} );

}.call(window));